.resolver-container {
  background-color: #1c1c1e;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  margin: 0 auto;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  color: white;
}

.resolver-toggle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.toggle-btn {
  background-color: #2c2c2e;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: 5px;
  margin: 0 5px;
}

.toggle-btn.active {
  background-color: #00ec00;
}

.resolver-content {
  text-align: center;
}

.resolver-select, .domain-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #2c2c2e;
  color: white;
  border: none;
  border-radius: 5px;
}

.resolver-select, .amount-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #2c2c2e;
  color: white;
  border: none;
  border-radius: 5px;
}
.send-btn {
  background-color: #00ec00;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  width: 100%;
}

.transfer-result {
  margin-top: 20px;
  color: #6bff52;
}
.success-message, .error-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeout 10s ease forwards;
}

.success-message {
  background-color: #d4edda;
  color: #155724;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
}

@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@media (max-width: 600px) {
  .success-message, .error-message {
    bottom: 10px;
    right: 10px;
    padding: 8px;
  }

  .resolver-container {
    padding: 10px;
  }

  .resolver-toggle button {
    font-size: 14px;
  }

  .resolver-select, .amount-input, .domain-input, .send-btn {
    font-size: 14px;
    padding: 8px;
  }
}

