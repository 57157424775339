body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Helvetica';
  align-items: center;
  background-color: #000000;
  overflow: hidden;
}

.App {
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-bar {
  position: fixed;
  top: 0;
  width: 100%;
  max-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures all items align vertically */
  background-color: #000000;
  box-shadow: 0 4px 4px #00ec00;
}

.logo h2 {
  color: #1ce609;
  padding-left: 10px;
  font-family: 'Helvetica';
  margin: 0;
  line-height: 35px;
}

a:link { 
  text-decoration: none; 
} 
a:visited { 
  text-decoration: none; 
} 
a:hover { 
  text-decoration: none; 
} 
a:active { 
  text-decoration: none; 
}


.nav-right {
  display: flex;
  gap: 15px;
  align-items: center;
}

.hamburger-menu {
  font-size: 2rem;
  padding-right: 10px;
  cursor: pointer;
  color: #00ec00;
}

.dropdown-menu {
  position: absolute;
  top: 55px;
  right: 10px;
  background-color: #333;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
  display: flex;
  flex-direction: column;
  transparent: 
}

.dropdown-item {
  background-color: transparent;
  color: #fff;
  border: none;
  padding: 10px 20px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: block;
  box-sizing: border-box;
}

.dropdown-item:hover {
  background-color: #444;
}

.modern-title {
  font-size: 3.5rem;
  color: #1ce609;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.block-part {
  color: #00ec00;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Helvetica;
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
  line-height: 4; /* Ensures text aligns well vertically */
  padding: 0; /* Remove extra padding */
  margin: 0;
}

@keyframes block-part-glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }
  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }
  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }
  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }
  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }
  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }
  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }
  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }
  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }
  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }
  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

.logo {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust this for spacing between image and text */
  padding-left: 5px;
  margin-top: 10px;
}

/* Logo image styling */
.logo-image {
  width: 40px;
  height: 40px;
}

.record-counter {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-family: 'Helvetica';
  margin-top: 40px;
}

.counter-block {
  width: 45px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #333;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.counter-block:hover {
  transform: translateY(-5px);
  background-color: #00ec00;
}

.title-container {
  text-align: center;
  position: relative;
  margin-top: 80px;
}

.buttons-container {
  display: flex;
  gap: 15px;
}

.modern-button {
  padding: 10px 20px;
  font-size: 0.8rem;
  border-radius: 6px;
  background-image: linear-gradient(-180deg, #00ec00, #007f00);
  color: #fff;
  cursor: pointer;
  border: none;
  display: inline-block;
  transition: background-color 0.3s ease, box-shadow 0.2s ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  text-align: center;
  outline: none;
  width: 100%;
  max-width: 100px;
}

.modern-button:hover {
  background-image: linear-gradient(-180deg, #00ff00, #00c800);
  box-shadow: rgba(0, 255, 0, 0.5) 0 3px 8px;
}

@media (max-width: 768px) {
  .modern-button {
    font-size: 0.8rem;
  }
}

@media (max-width: 480px) {
  .modern-button {
    font-size: 0.8rem;
    padding: 8px 12px;
  }
}



.subtitle {
  font-size: 1rem;
  color: #FFCB9A;
  margin-top: 10px;
}

.search-container {
  margin-top: 20px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.search-bar {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid #FFCB9A;
}

/* Mobile view */
@media (max-width: 768px) {
  .search-bar {
    width: 100%; /* Fills width on mobile */
    max-width: 300px; /* Max width constraint for mobile */
    font-size: 1rem;
  }
}

/* Tablet and smaller desktop view */
@media (min-width: 769px) and (max-width: 1023px) {
  .search-bar {
    width: 100%;
    max-width: 300px; /* Adjusts to 500px on smaller desktops and tablets */
  }
}

/* Larger desktop view */
@media (min-width: 1024px) {
  .search-bar {
    width: 300px; /* Fixed width on larger screens */
    font-size: 1rem;
    padding: 10px;
  }
}


.resolver-bar {
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 5px;
  border: 1px solid #FFCB9A;
  width: 350px;
}

.domain-status {
  margin-top: 20px;
  padding: 15px;
  border-radius: 5px;
  font-size: 1.2rem;
  color: #fff;
}

.available {
  background-color: #00ec00;
  position: bottom right;
}

.taken {
  background-color: #dc3545;
}

.wallet-info {
  margin-top: 10px;
  color: #FFF;
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 1rem;
}

.profile-page {
  padding: 20px;
  padding-left: 20px;
  background-color: #000000;
  color: #fff;
  height: auto;
  min-height: 100vh;
  overflow-y: auto;
}
.profile-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 20px;
}

.domain-card-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.domain-card {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  width: 380px;
  max-width: 100%;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  overflow-wrap: break-word;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 10px;
}

.nft-image {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}

.nft-image img {
  width: 100%;
  height: 100%;
}

.modal h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: white;
}

.modal input[type="range"] {
  width: 100%;
  margin: 20px 0;
  color: white;
}

.modal p {
  margin-top: 10px;
  color: white;
}

.modal label {
	color: white;
}

.card-buttons {
  margin-top: 10px;
}

.card-buttons .modern-button {
  margin-right: 10px;
}

.cube-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding-top: 200px;
}

.cube {
  position: relative;
  width: 150px;
  height: 150px;
  transform-style: preserve-3d;
  transform: rotateX(-30deg);
  animation: animate 4s linear infinite;
}

@keyframes animate
{
	0%
	{
		transform: rotateX(-30deg) rotateY(0deg);
	}
	100%
	{
		transform: rotateX(-30deg) rotateY(360deg);
	}
}

.cube div
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transform-style: preserve-3d;
}

.cube div span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#050505, #00ec00);
  transform: rotateY(calc(90deg * var(--i))) translateZ(75px);
}
@media (max-width: 768px) {
  .cube {
    width: 150px;
    height: 150px;
  }
}
@media (max-width: 480px) {
  .cube {
    width: 150px;
    height: 150px;
  }
}
.top
{
	position: absolute;
	top: 0;
	left: 0;
	width: 150px;
	height: 150px;
	background: #050505;
	transform: rotateX(90deg) translateZ(75px);
}

.top::before
{
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 150px;
	height: 150px;
	background: #050505;
	transform: translateZ(-230px);
	filter: blur(20px);
	box-shadow: 
		0 0 100px rgba(0,255,0,0.2),
		0 0 200px rgba(0,255,0,0.4),
		0 0 300px rgba(0,255,0,0.6),
		0 0 400px rgba(0,255,0,0.8),
		0 0 500px rgba(0,255,0,1);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  z-index: 999;
}

.modal {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
}
.success-message, .error-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  border-radius: 5px;
  z-index: 9999;
  font-family: Arial, sans-serif;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: fadeout 20s ease forwards;
}
.success-message {
  background-color: #28a745;
  color: white;
}

.error-message {
  background-color: #dc3545;
  color: white;
}

@keyframes fadeout {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.modal-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-content {
  background-color: #2a2a2a;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  text-align: center;
}

.modal.show {
  visibility: visible;
}
