.about-container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
  color: #333;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

.about-container h1 {
  font-size: 2.5em;
  color: #fff;
  text-align: center;
  padding-top: 30px;
  margin-bottom: 20px;
}

.about-section {
  margin-bottom: 30px;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.about-section h2 {
  font-size: 1.8em;
  color: #666;
  margin-bottom: 10px;
}

.about-section p {
  font-size: 1em;
  line-height: 1.6;
  color: #555;
}

.about-container::-webkit-scrollbar {
  width: 8px;
}

.about-container::-webkit-scrollbar-track {
  background: transparent;
}

.about-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.about-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

.about-container {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}
