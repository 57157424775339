.metadata-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  color: #fff;
  text-align: center;
}

.dns-header {
	color: #fff;
	text-align: center;
}


.add-record {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
}

.domain-select, .record-type-select, .record-value-input, .mod-button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
}

.add-button {
  background-color: #00ec00;
  color: #ffffff;
  font-weight: bold;
  max-height: 40px;
  border-radius: 5px;
  padding: 8px 12px;
}

.save-records-container {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.save-button {
  padding: 8px 12px;
  font-weight: bold;
  font-size: 0.8rem;
  max-height: 40px;
}


.records-table {
  width: 100%;
  margin-top: 20px;
}

.records-table table {
  width: 100%;
  border-collapse: collapse;
  color: #ffffff;
}

.records-table th, .records-table td {
  padding: 12px;
  border-bottom: 1px solid #00ec00;
}

.records-table th {
  background-color: #333;
  color: #00ec00;
}

.records-table td {
  background-color: #222;
  text-align: center;
}

button:hover {
  background-color: #1ce609;
}
